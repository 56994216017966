<template>
    <div class="main-contents code oper">
        <div class="tit">능력단위 등록/수정</div>
        <div class="search-box interval">
            <SelectComp :list="midNameList" codeKey="midClassCd" nameKey="midClassNm" v-model="srchFilter.ncsMidSeq" :isAll="true" title="중분류" @change="loadMinor()"/>&nbsp; 
            <SelectComp :list="minorNameList" codeKey="minorClassCd" nameKey="minorClassNm" v-model="srchFilter.ncsMinorSeq" :isAll="true" title="소분류" @change="loadDetail()"/>&nbsp;
            <SelectComp :list="detailNameList" codeKey="detailClassCd" nameKey="detailClassNm" v-model="srchFilter.ncsDetaSeq" :isAll="true" title="세분류"/>&nbsp;
            <SelectComp list="Y:사용,N:사용안함" v-model="srchFilter.srchUse" class="wd01" :isAll="true" title="사용여부"/>&nbsp;
            <SelectComp list="name:능력단위명,cd:능력단위코드" v-model="srchFilter.srchType" @change="placeholderTxt = $event.target.selectedOptions[0].label" class="wd01" title="능력단위명" />
            <InputComp v-model="srchFilter.srchTxt" :placeholder="placeholderTxt" @keyup.enter="getAbilUnitList('1'), check(srchFilter.ncsMidSeq), check1(srchFilter.ncsMinorSeq), check2(srchFilter.ncsDetaSeq)" />
            <img class="search_btn" src="/images/admin/search_btn.png" alt="검색버튼" @click="getAbilUnitList('1'), check(srchFilter.ncsMidSeq), check1(srchFilter.ncsMinorSeq), check2(srchFilter.ncsDetaSeq)"/>
            <div class="sub_btn"  @click="$router.push('MAN936M03')">
                + 등록
            </div>
        </div>
        <!-- 능력단위 등록/수정 리스트 -->
        <div class="Board type3">
            <div class="cf_title">4. 능력단위명을 선택하세요</div>
            <div class="catg" v-if="this.midName == ''"></div>
            <div class="catg" v-else >[정보통신]  {{this.midName}}{{this.minorName}}{{this.detailName}}</div>
            <table class="Board_type3 admin">
                <colgroup>
                    <col width="4%">
                    <col width="9%">
                    <col width="10%">
                    <col width="10.5%">
                    <col width="15%">
                    <col width="4%">
                    <col width="13%">
                    <col width="22%">
                    <col width="5%">
                    <col width="7.5%">
                </colgroup> 
                <thead>
                    <tr>
                        <th>NO</th>
                        <th>[대분류명]</th>
                        <th>1.중분류명</th>
                        <th>2.소분류명</th>
                        <th>3.세분류명</th>
                        <th>레벨</th>
                        <th>능력단위코드</th>
                        <th>4.능력단위명</th>
                        <th>사용<br>여부</th>
                        <th>수정</th>
                    </tr>
                </thead>
                <tbody v-if="abilUnitList.length > 0">
                    <tr v-for="(abil, abilindex) in abilUnitList" :key="abilindex">
                        <td class="score">{{(pageInfo.pageIndex - 1) * pageInfo.pageUnit + (abilindex+1)}}</td>
                        <td class="score">{{abil.majorClassNm}}</td>
                        <td class="score">{{abil.midClassNm}}</td>
                        <td class="score">{{abil.minorClassNm}}</td>
                        <td class="score">{{abil.detailClassNm}}</td>
                        <td class="score">{{abil.levelCd}}</td>
                        <td class="score">{{abil.abilUnitCd}}</td>
                        <td class="score name" @click="viewUnitInfo(abil)">{{abil.abilUnitNm}} &#9654;</td>
                        <td class="score">
                            <SelectComp type="text" :value="abil.useYn" list="Y:사용,N:사용안함"/>
                        </td>
                        <td class="score name" @click="viewAbilDetail(abil)"><img src="/images/admin/cf_modify_btn.png" alt="수정버튼"/></td>
                    </tr>
                </tbody>
                <tbody v-else>
                <!-- 관련내용 없을 경우 -->
                    <tr class="none">
                        <td colspan="10" class="none">등록된 능력단위항목이 없습니다!</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <!-- 화면 up & down 버튼 -->
        <div class="screen_btn">
            <img src="/images/admin/screen-top_btn.png" alt="탑버튼"/>
            <img src="/images/admin/screen-down_btn.png" alt="다운버튼"/>
        </div>
        <!-- 페이징부분 -->
        <pagingComp :pageInfo="pageInfo" @page-click="goPage"/>
    </div>
</template>

<script>
import pagingComp from '@/components/PagingComp.vue';
import SelectComp from '@/components/base/SelectComp.vue';

export default {

    data() {
        return {

            ncsMajorSeq : this.$route.params.ncsMajorSeq || '',
            ncsMidSeq : this.$route.params.ncsMidSeq || '',
            ncsMinorSeq : this.$route.params.ncsMinorSeq || '',
            ncsDetaSeq : this.$route.params.ncsDetaSeq || '',
            midName : this.$route.params.midName || '',
            minorName : this.$route.params.minorName || '',
            detailName : this.$route.params.detailName || '',

            //검색조건
			srchFilter : {
                ncsMajorSeq : '20',
				ncsMidSeq : '',
                ncsMinorSeq : '',
                ncsDetaSeq : '',
                srchUse : '',
                srchType : 'name',
				srchTxt : '',
				pageIndex : 1,
                midName : '',
                minorName : '',
                detailName : '',
			},

            abilUnitList : [],

            midNameList : [],
            minorNameList : [],
            detailNameList : [],
            
            placeholderTxt : '능력단위명',

            //페이징
			pageInfo : {},
        }
    },

    mounted() {
		var keys = Object.keys(this.srchFilter);
		for(var key in this.$route.params){
			if(keys.indexOf(key)>=0){
				this.srchFilter[key] = this.$route.params[key];
			}
		}
        this.getAbilUnitList();
	},

    beforeMount() {
        this.loadMid();
        if(this.ncsDetaSeq != '') {
            this.getAbilUnitList();
            this.loadMid();
            // this.loadMinor();
            // this.loadDetail();
            this.ncsDetaSeq = '';
        }
        this.srchFilter.midName = this.midName;
        this.srchFilter.minorName = this.minorName;
        this.srchFilter.detailName = this.detailName;
	},


    methods: {

        getAbilUnitList(div) {
            var param = {};
            if(this.ncsDetaSeq != '') {
                this.srchFilter.ncsMajorSeq = this.ncsMajorSeq;
                this.srchFilter.ncsMidSeq = this.ncsMidSeq;
                this.srchFilter.ncsMinorSeq = this.ncsMinorSeq;
                this.srchFilter.ncsDetaSeq = this.ncsDetaSeq;
                // this.midName = '1. ' + this.midName;
                // this.minorName = ' > 2. ' + this.minorName;
                // this.detailName = ' > 3. ' + this.detailName;

                if(this.midName.indexOf('1') == -1) {
                    this.midName = '1. ' + this.midName;
                    this.minorName = ' > 2. ' + this.minorName;
                    this.detailName = ' > 3. ' + this.detailName;
                }

            }
            param = this.srchFilter;

            param.pageUnit = '25';
            param.pageSize = '10';
            if(div) param.pageIndex = 1;

            // console.log('adsf',param);

            this.$.httpPost('/api/main/adm/ncsinout/getAbilUnitList', param)
				.then(res => {
                    
                    // console.log('getAbilUnitList RESULT', res);

                    this.abilUnitList = res.data.abilUnitList;
                    this.pageInfo = res.data.pageInfo;

				}).catch(this.$.httpErrorCommon);
        },

        //페이징 이동
        goPage(page) {
			this.srchFilter.pageIndex = page;
			this.getAbilUnitList();
		},

        loadMid() {
            this.$.httpPost('/api/main/adm/ncsinout/loadMidList')
                .then(res => {
                    // console.log('loadMidList RESULT', res);
                    this.midNameList = res.data.loadMidList;

                    if(this.srchFilter.ncsMidSeq != '') this.loadMinor();
                }).catch(this.$.httpErrorCommon);
        },

        loadMinor() {
            if(this.srchFilter.ncsMidSeq == '') {
                this.srchFilter.ncsMinorSeq = '';
                this.minorNameList = [];
                if(this.srchFilter.ncsMinorSeq == '') {
                    this.srchFilter.ncsDetaSeq = '';
                    this.detailNameList = [];
                }
            } else {
                this.detailNameList = [];
                var params = {};
                params.midClassCd = this.srchFilter.ncsMidSeq;
                this.$.httpPost('/api/main/adm/ncsinout/loadMinorList' , params)
                    .then(res => {
                        // console.log('loadMinorList RESULT', res);
                        // console.log(22222);
                        // console.log(res);
                        this.minorNameList = res.data.loadMinorList;

                        if(this.srchFilter.ncsMinorSeq != '') this.loadDetail();
                    }).catch(this.$.httpErrorCommon);
            }
        },

        loadDetail() {
            if(this.srchFilter.ncsMinorSeq == '') {
                this.srchFilter.ncsDetaSeq = '';
                this.detailNameList = [];
            } else {
                var params = {};
                params.minorClassCd = this.srchFilter.ncsMinorSeq;

                this.$.httpPost('/api/main/adm/ncsinout/loadDetailList' , params)
                    .then(res => {
                        // console.log('loadDetailList RESULT', res);
                        // console.log(22222);
                        // console.log(res);
                        this.detailNameList = res.data.loadDetailList;
                    }).catch(this.$.httpErrorCommon);
            }
        },

        check(cd) {
            if(cd == '') {
                this.midName = '';
                this.minorName = '';
                this.detailName = '';
            } else {
                for(var item in this.midNameList) {
                // console.log(item, '2', cd, this.midNameList[item].midClassCd);
                    if(this.midNameList[item].midClassCd == cd) {
                        this.midName = '1. ' + this.midNameList[item].midClassNm;
                    }
                }
                this.minorName = '';
                this.detailName = '';
            }
        },
        check1(cd) {
            if(cd == '') {
                this.minorName = '';
                this.detailName = '';
            } else {
                for(var item in this.minorNameList) {
                // console.log(item, '2', cd, this.midNameList[item].midClassCd);
                    if(this.minorNameList[item].minorClassCd == cd) {
                        this.minorName = ' > 2. ' + this.minorNameList[item].minorClassNm;
                    }
                }
                this.detailName = '';
            }
        },
        check2(cd) {
            if(cd == '') {
                this.detailName = '';
            } else {
                for(var item in this.detailNameList) {
                // console.log(item, '2', cd, this.midNameList[item].midClassCd);
                    if(this.detailNameList[item].detailClassCd == cd) {
                        this.detailName = '> 3. ' + this.detailNameList[item].detailClassNm;
                    }
                }
            }
        },

        viewAbilDetail(abil) {
            this.srchFilter.midName = this.midName;
            this.srchFilter.minorName = this.minorName;
            this.srchFilter.detailName = this.detailName;
            var params = {
                abilSeq : abil.abilUnitCd,
                caller : {
                    name : this.$route.name,
                    params : this.srchFilter
                }
            }

            // console.log(this.$router);
            this.$router.push({name: 'MAN936M03', params });
        },

        viewUnitInfo(abil) {
            // console.log(abil);
            var params = {
                ncsMajorSeq : abil.majorClassCd,
                ncsMidSeq : abil.midClassCd,
                ncsMinorSeq : abil.minorClassCd,
                ncsDetaSeq : abil.detailClassCd,
                ncsAbilSeq : abil.abilUnitCd ,
                midName : abil.midClassNm,
                minorName : abil.minorClassNm,
                detailName : abil.detailClassNm,
                abilName : abil.abilUnitNm,
                caller : {
                    name : this.$router.name,
                    params : this.srchFilter
                }
            }

            // console.log(this.$router);

            this.$router.push({name: 'MAN936M04', params});
        },

    },

    components : {
    pagingComp,
    SelectComp
},

    

}
</script>